<template>
  <a-row :gutter="20">
    <a-col :span="11">
      <div class="mb-8">
        <a-input-search placeholder="请输入名称" @change="handleChange" />
      </div>
      <a-space class="mb-8">
        <a-button type="primary" @click="handleClickAdd">
          <template #icon><plus-outlined /></template>
          新增
        </a-button>
        <a-button @click="handleClickDelete">
          <template #icon><delete-outlined /></template>
          批量删除
        </a-button>
        <a-button @click="handleClickExport">
          <template #icon><export-outlined /></template>
          批量导出
        </a-button>
      </a-space>
      <a-spin :spinning="spinning">
        <a-tree
          class="iss-tree"
          checkable
          check-strictly
          block-node
          v-model:expanded-keys="expandedKeys"
          v-model:checked-keys="checkedKeys"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          :replace-fields="{ key: 'id' }"
          @select="handleSelect"
        >
          <template #title="{ name, status }">
            <div class="iss-tree-item">
              <span class="item-title">{{ name }}</span>
              <a-badge :status="status ? 'success' : 'error'" />
            </div>
          </template>
        </a-tree>
      </a-spin>
    </a-col>
    <a-col :span="13">
      <a-card :title="form.id ? '编辑' : '新增'" class="iss-card">
        <template #extra>
          <a-button type="primary" @click="handleSave">保存</a-button>
        </template>
        <a-form
          class="iss-form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-form-item label="父级">
            <span>{{ form.parentName }}</span>
          </a-form-item>
          <a-form-item label="名称" v-bind="validateInfos.name">
            <a-input v-model:value="form.name" />
          </a-form-item>
          <a-form-item label="简称" v-bind="validateInfos.abbreviation">
            <a-input v-model:value="form.abbreviation" />
          </a-form-item>
          <a-form-item label="状态">
            <a-switch
              v-model:checked="form.status"
              checked-children="启用"
              un-checked-children="禁用"
            />
          </a-form-item>
          <a-form-item label="排序">
            <a-input-number v-model:value="form.sortValue" :min="0" />
          </a-form-item>
          <a-form-item label="描述">
            <a-textarea v-model:value="form.describe" />
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { createVNode, reactive, toRaw, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import {
  Badge,
  Card,
  Col,
  Form,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Tree,
} from 'ant-design-vue';
import {
  PlusOutlined,
  DeleteOutlined,
  ExportOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { filterTree, getTreeItem } from '@/utils';
import orgApi from '@/api/org.js';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    ASpin: Spin,
    ATree: Tree,
    ABadge: Badge,
    ACard: Card,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    AInputNumber: InputNumber,
    PlusOutlined,
    DeleteOutlined,
    ExportOutlined,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      spinning: false,
      treeData: [],
      expandedKeys: [],
      selectedKeys: [],
      checkedKeys: { checked: [] },
    });
    const form = reactive({
      id: '',
      parentId: 0,
      parentName: '根结点',
      name: '',
      abbreviation: '',
      status: true,
      sortValue: 0,
      describe: '',
    });
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      name: [required],
    });
    let cacheTrees = [];
    const initTreeData = () => {
      state.spinning = true;
      orgApi
        .getList(route.path)
        .then(data => {
          cacheTrees = data.concat();
          state.treeData = data;
          state.expandedKeys = data.map(i => i.id);
        })
        .finally(() => {
          state.spinning = false;
        });
    };
    const handleClickAdd = () => {
      state.selectedKeys = [];
      resetFields();
      const checked = state.checkedKeys.checked;
      if (checked.length == 1) {
        form.parentId = checked[0];
        form.parentName = getTreeItem(
          cacheTrees,
          item => item.id === checked[0]
        ).name;
      } else if (checked.length > 1) {
        message.info('只能选中一条数据新增其子节点');
      }
    };
    initTreeData();
    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleClickAdd,
      handleSave: () => {
        validate().then(() => {
          let type = 'add';
          form.id && (type = 'update');
          orgApi[type](`org:${type}`, toRaw(form)).then(() => {
            message.success('操作成功');
            handleClickAdd();
            initTreeData();
          });
        });
      },
      handleClickDelete: () => {
        const ids = state.checkedKeys.checked;
        if (ids.length) {
          Modal.confirm({
            title: `确定要删除选中的 ${ids.length} 条数据吗？`,
            icon: createVNode(ExclamationCircleOutlined),
            okType: 'danger',
            onOk: () => {
              orgApi.delete('org.delete', { ids }).then(() => {
                message.success('操作成功');
                initTreeData();
              });
            },
          });
        } else {
          message.info('请至少选择一条数据');
        }
      },
      handleClickExport: () => {},
      handleChange: ({ target }) => {
        const { trees, parents } = filterTree(
          cacheTrees,
          item => item.name.indexOf(target.value) > -1
        );
        state.treeData = trees;
        state.expandedKeys = parents.map(i => i.id);
      },
      handleSelect: (keys, { selected, selectedNodes }) => {
        if (selected) {
          state.selectedKeys = keys;
          const node = selectedNodes[0].props.dataRef;
          Object.assign(form, node, {
            parentName:
              node.parentId === '0'
                ? '根结点'
                : getTreeItem(cacheTrees, item => item.id === node.parentId)
                    .name,
          });
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-tree {
  height: calc(100vh - 188px);
  overflow-y: auto;
}
.iss-tree-item {
  display: flex;
  align-items: center;
  .item-title {
    flex: 1;
  }
  .anticon {
    font-size: 16px;
  }
}
.iss-form {
  height: calc(100vh - 265px);
  overflow-y: auto;
}
</style>
